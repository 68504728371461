.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #f8f9fa;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

.loading-content {
  position: relative;
  width: 250px;
  height: 250px;
  margin-bottom: 2rem;
}

/* Logoen står i ro i midten */
.loading-logo {
  font-size: 2.5rem;
  font-weight: 700;
  color: #558392;
  font-family: 'Poppins', sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}

.loading-spinner {
  width: 200px;
  height: 200px;
  border: 4px solid rgba(85, 131, 146, 0.2);
  border-top: 4px solid #558392;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
}

/* Regndråper container */
.raindrops {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* Individuell regndråpe - nå med dråpeform */
.raindrop {
  position: absolute;
  background-color: rgba(85, 131, 146, 0.6);
  width: 15px;
  height: 22px; /* Lengre høyde for dråpeform */
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%; /* Gir dråpeform */
  opacity: 0;
  transform: scale(0) rotate(135deg); /* Roterer for riktig retning */
  animation: falling 3s ease-in infinite;
}

/* Ulike animasjonsforsinkelser for hver dråpe */
.raindrop:nth-child(2) {
  animation-delay: 0.3s;
  left: 20%;
}
.raindrop:nth-child(3) {
  animation-delay: 0.6s;
  left: 40%;
}
.raindrop:nth-child(4) {
  animation-delay: 0.9s;
  left: 60%;
}
.raindrop:nth-child(5) {
  animation-delay: 1.2s;
  left: 80%;
}
.raindrop:nth-child(6) {
  animation-delay: 1.5s;
  left: 10%;
}
.raindrop:nth-child(7) {
  animation-delay: 1.8s;
  left: 70%;
}
.raindrop:nth-child(8) {
  animation-delay: 2.1s;
  left: 30%;
}
.raindrop:nth-child(9) {
  animation-delay: 2.4s;
  left: 50%;
}
.raindrop:nth-child(10) {
  animation-delay: 2.7s;
  left: 90%;
}

/* Ulike størrelser for hver dråpe */
.raindrop:nth-child(3n) {
  width: 10px;
  height: 15px;
}
.raindrop:nth-child(3n + 1) {
  width: 15px;
  height: 22px;
}
.raindrop:nth-child(3n + 2) {
  width: 20px;
  height: 30px;
}

/* Animasjon for fallende dråper */
@keyframes falling {
  0% {
    top: -20px;
    opacity: 0;
    transform: scale(0) rotate(135deg);
  }
  10% {
    opacity: 1;
    transform: scale(1) rotate(135deg);
  }
  80% {
    opacity: 0.7;
  }
  100% {
    top: 120%;
    opacity: 0;
    transform: scale(0.5) rotate(135deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Add this update to the loading-info section */
.loading-info {
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 80%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 30px; /* Increased from 20px to create more space */
}

.loading-message {
  color: #558392;
  font-size: 1rem;
  font-style: italic;
  text-align: center;
  opacity: 1;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Add position relative */
  z-index: 2; /* Make sure text is above progress bar */
}

/* Add a new container for progress elements */
.progress-container {
  position: relative;
  width: 100%;
  padding-top: 15px; /* Add padding on top */
  z-index: 1;
}

.progress-bar {
  width: 100%;
  height: 4px;
  background-color: rgba(85, 131, 146, 0.2);
  border-radius: 2px;
  overflow: hidden;
  margin: 0; /* Reset margin */
}

.progress-text {
  color: #558392;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 8px; /* Add more margin */
  min-height: 20px;
}
